<template>
	<!--现货资源-->
	<div class="index-resources">
		<div class="resources-nav">
			<div class="resources-title">
				<p>现货资源</p>
			</div>
			<div class="resources-tab">
				<div :class="{
            'resources-item': true,
            active: resourcesTab == index,
          }" v-for="(item, index) in resourcesList" :key="index" @click="getClick(item, index)">
					{{ item }}
				</div>
			</div>
		</div>
		<div class="resources-list">
			<!--一条数据开始-->
			<div class="resources-list-item" v-for="(item,index) in homeMallList" :key="index">
				<div class="itemTitle">
					<div class="leftTitle">
						<span>{{item.title}}</span><span>{{item.subTitle}}</span><span>{{item.subTitle2}}</span>
					</div>
					<div class="rightTitle">{{item.time}}</div>
				</div>
				<div class="itemTitle">
					<div class="leftTitle2"><span>{{item.title2}}</span><span>{{item.title3}}</span></div>
					<div class="rightTitle2">电议</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapState,
		mapActions
	} from "vuex";
	import dayjs from "dayjs";
	export default {
		name: "resources",
		data() {
			return {
				resourcesTab: 0,
				resourcesList: ['建筑钢材', '热卷', '中厚板'],
				homeMallList: [{
						"title": "南京",
						"subTitle": "螺纹钢",
						"subTitle2": "萍九钢",
						"time": "",
						"title2": "HRB400E",
						"title3": "18*9"
					},
					{
						"title": "上海",
						"subTitle": "螺纹钢",
						"subTitle2": "萍九钢",
						"time": "",
						"title2": "HRB400E",
						"title3": "20*9"
					},
					{
						"title": "苏州",
						"subTitle": "螺纹钢",
						"subTitle2": "永钢",
						"time": "",
						"title2": "HRB400E",
						"title3": "16*9"
					},
					{
						"title": "无锡",
						"subTitle": "螺纹钢",
						"subTitle2": "永钢",
						"time": "",
						"title2": "HRB400E",
						"title3": "22*9"
					},
					{
						"title": "扬州",
						"subTitle": "螺纹钢",
						"subTitle2": "马长江",
						"time": "",
						"title2": "HRB400E",
						"title3": "12*9"
					},
					{
						"title": "常州",
						"subTitle": "螺纹钢",
						"subTitle2": "中天",
						"time": "",
						"title2": "HRB400E",
						"title3": "25*9"
					},
				]
			};
		},
		created() {
			this.getTime();
		},
		methods: {
			getTime() {
				this.homeMallList.map(item => {
					let randomTime = this.getRandomDateTime();
					item.time = dayjs(randomTime).format("MM-DD");
					item.time = item.time + " 07:30";
					return item
				})
				this.homeMallList = this.homeMallList.sort((a, b) => new Date(a.time) - new Date(b.time));
			},
			getRandomDateTime() {
				const start = dayjs().startOf('day').add(8, 'hour');
				const end = dayjs().endOf('day');
				const duration = end.diff(start);
				const randomMs = Math.random() * duration;
				return start.add(randomMs, 'milliseconds');
			},
			getClick(item, index) {
				this.resourcesTab = index
				if (this.resourcesTab == 0) {
					this.homeMallList = [{
							"title": "南京",
							"subTitle": "螺纹钢",
							"subTitle2": "萍九钢",
							"time": "",
							"title2": "HRB400E",
							"title3": "18*9"
						},
						{
							"title": "上海",
							"subTitle": "螺纹钢",
							"subTitle2": "萍九钢",
							"time": "",
							"title2": "HRB400E",
							"title3": "20*9"
						},
						{
							"title": "苏州",
							"subTitle": "螺纹钢",
							"subTitle2": "永钢",
							"time": "",
							"title2": "HRB400E",
							"title3": "16*9"
						},
						{
							"title": "无锡",
							"subTitle": "螺纹钢",
							"subTitle2": "永钢",
							"time": "",
							"title2": "HRB400E",
							"title3": "22*9"
						},
						{
							"title": "扬州",
							"subTitle": "螺纹钢",
							"subTitle2": "马长江",
							"time": "",
							"title2": "HRB400E",
							"title3": "12*9"
						},
						{
							"title": "常州",
							"subTitle": "螺纹钢",
							"subTitle2": "中天",
							"time": "",
							"title2": "HRB400E",
							"title3": "25*9"
						},
					]
				} else if (this.resourcesTab == 1) {
					this.homeMallList = [{
							"title": "宁波",
							"subTitle": "热轧卷板",
							"subTitle2": "宁钢",
							"time": "",
							"title2": "Q235B",
							"title3": "3.75*1500*C"
						},
						{
							"title": "宁波",
							"subTitle": "热轧卷板",
							"subTitle2": "宁钢",
							"time": "",
							"title2": "Q235B",
							"title3": "4.75*1500*C"
						},
						{
							"title": "宁波",
							"subTitle": "热轧卷板",
							"subTitle2": "宁钢",
							"time": "",
							"title2": "Q235B",
							"title3": "5.75*1500*C"
						},
						{
							"title": "江阴",
							"subTitle": "热轧卷板",
							"subTitle2": "宁钢",
							"time": "",
							"title2": "Q235B",
							"title3": "4.5*1500*C"
						},
						{
							"title": "江阴",
							"subTitle": "热轧卷板",
							"subTitle2": "日照",
							"time": "",
							"title2": "Q235B",
							"title3": "7.5*1500*C"
						},
						{
							"title": "江阴",
							"subTitle": "热轧卷板",
							"subTitle2": "宁钢",
							"time": "",
							"title2": "Q235B",
							"title3": "9.25*1500*C"
						},
					]
				} else if (this.resourcesTab == 2) {
					this.homeMallList = [{
							"title": "上海",
							"subTitle": "中厚板",
							"subTitle2": "西城",
							"time": "",
							"title2": "Q235B",
							"title3": "14*2500*L"
						},
						{
							"title": "上海",
							"subTitle": "中厚板",
							"subTitle2": "西城",
							"time": "",
							"title2": "Q235B",
							"title3": "16*2500*L"
						},
						{
							"title": "上海",
							"subTitle": "中厚板",
							"subTitle2": "西城",
							"time": "",
							"title2": "Q235B",
							"title3": "8*2000*L"
						},
						{
							"title": "上海",
							"subTitle": "中厚板",
							"subTitle2": "华伟",
							"time": "",
							"title2": "Q235B",
							"title3": "18*2000*L"
						},
						{
							"title": "上海",
							"subTitle": "中厚板",
							"subTitle2": "华伟",
							"time": "",
							"title2": "Q235B",
							"title3": "25*2500*L"
						},
						{
							"title": "上海",
							"subTitle": "中厚板",
							"subTitle2": "华伟",
							"time": "",
							"title2": "Q235B",
							"title3": "28*2500*L"
						},
					]
				}
				this.getTime();
			},
		},
		computed: {

		},
	};
</script>

<style lang="scss">
	.index-resources {
		width: 1200px;
		margin: 0px auto;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		padding-bottom: 20px;
	}

	.index-resources .resources-nav {
		display: flex;
		width: 1200px;
		margin: 30px auto;
		margin-top: 50px;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
	}

	.index-resources .resources-nav .resources-title {
		display: flex;
		align-items: center;
		margin-right: 30px;
		width: 188px;
		caret-color: rgba(0, 0, 0, 0);
		cursor: default;
	}

	.index-resources .resources-nav .resources-title p {
		font-family: SourceHanSansCN, SourceHanSansCN;
		font-weight: 600;
		font-size: 18px;
		color: #000102;
		line-height: 26px;
		text-align: left;
		font-style: normal;
	}

	.index-resources .resources-nav .resources-tab {
		display: flex;
		width: 600px;
		justify-content: flex-end;
		align-items: center;
	}

	.index-resources .resources-nav .resources-tab .resources-item {
		width: 80px;
		height: 30px;
		color: #000102;
		display: flex;
		align-items: center;
		justify-content: center;
		caret-color: rgba(0, 0, 0, 0);
		cursor: pointer;
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 14px;
		font-style: normal;
		margin-left: 50px;
	}

	.pointer {
		cursor: pointer;
	}

	.index-resources .resources-nav .resources-tab .resources-item.active {
		width: 80px;
		height: 30px;
		border-radius: 15px;
		border: 1px solid #0285ED;
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 14px;
		color: #0285ED;
		font-style: normal;
		text-align: center;
		justify-content: center;
	}



	.index-resources .resources-list {
		width: 1200px;
		height: 220px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

	}

	.index-resources .resources-list .resources-list-item {
		width: 348px;
		height: 52px;
		display: flex;
		border-radius: 4px;
		border: 1px solid #E7E7E7;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		padding: 16px;
		padding-bottom: 10px;
		cursor: pointer;

	}

	.index-resources .resources-list .resources-list-item:hover {
		background: #F9FCFF;
		border-radius: 4px;
		border: 1px solid #0285ED;

	}

	.itemTitle {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.itemTitle span {
		margin-right: 10px;
	}

	.leftTitle {
		font-family: SourceHanSansCN, SourceHanSansCN;
		font-weight: 400;
		font-size: 14px;
		color: #666666;
		font-style: normal;
	}

	.rightTitle {
		font-family: SourceHanSansCN, SourceHanSansCN;
		font-weight: 400;
		font-size: 12px;
		color: #999999;
		text-align: left;
		font-style: normal;
	}

	.leftTitle2 {
		font-family: SourceHanSansCN, SourceHanSansCN;
		font-weight: 500;
		font-size: 14px;
		color: #000102;
		text-align: left;
		font-style: normal;
	}

	.rightTitle2 {
		font-family: SourceHanSansCN, SourceHanSansCN;
		font-weight: 400;
		font-size: 18px;
		color: #E01C1C;
		text-align: left;
		font-style: normal;
	}
</style>
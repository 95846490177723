<!--
 * @Author: luoyan
 * @Date: 2021-10-29 15:21:18
 * @LastEditTime: 2021-11-16 14:18:17
 * @Description: file content  推荐商铺
-->
<template>
	<!--推荐商铺-->
	<div class="index-recommend">
		<div class="recommend-nav">
			<div class="recommend-title">

				<p>推荐商铺</p>
			</div>
			<div class="recommend-tab">
				<div :class="{
            'recommend-item': true,
            active: recommendTab == index,
          }" v-for="(item, index) in regionList" :key="index" @click="tabRegion(index, item)">
					{{ item }}
				</div>
			</div>
		</div>

		<div class="index-recommend-list">
			<!--一条数据开始-->
			<div class="recommend-list-item" v-for="(item, index) in companyList" :key="index">
				<div class="list-item-head">
					<div class="list-item-head-left-img">
						{{item.title}}
					</div>
					<div class="list-item-head-left-text">
						<div class="list-item-head-left-text-name">
							{{item.name}}
						</div>
						<div class="list-item-head-left-text-name2" v-if="item.zy == 1">
							近1月 成交{{item.num}}万吨
							<img style="width:50px;height:20px;margin-left:10px;" src="../../../assets/img/zy.png" />
						</div>
						<div class="list-item-head-left-text-name2" v-else>
							<img style="width:50px;height:20px;" src="../../../assets/img/fzy.png" />
						</div>
					</div>
				</div>
				<div class="list-item-footer">
					<div class="footerItem">
						<div class="footerHead">
							<div>{{item.subTitle}}</div>
							<div>{{item.subTitle2}}</div>
						</div>
						<div class="footerHead">
							<div>{{item.subTitle3}}</div>
							<div>{{item.subTitle4}}</div>
						</div>
						<div class="footerTitle">
							<div>{{item.subTitle5}}</div>
							<div>{{item.subTitle6}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapState,
		mapActions
	} from "vuex";
	import dayjs from "dayjs";
	export default {
		name: "recommend",
		data() {
			return {
				recommendTab: 0,
				companyList: [{
					zy: 1,
					"title": "宝汇",
					"name": "浙江宝汇大宗供应链有限公司",
					"num": '4.35',
					"time": "",
					"subTitle": "萍钢/永钢",
					"subTitle2": "螺纹钢/盘螺",
					"subTitle3": "(12-32)*9",
					"subTitle4": "HRB400E/HRB500E",
					"subTitle5": "江苏",
					"subTitle6": "南京中储库",

				}, {
					zy: 1,
					"title": "晨华",
					"name": "浙江晨华供应链有限公司",
					"num": '3.8',
					"time": "",
					"subTitle": "萍九钢/永钢/中天",
					"subTitle2": "螺纹钢/盘螺",
					"subTitle3": "(12-32)*9",
					"subTitle4": "HRB400/HRB400E",
					"subTitle5": "上海/江苏/浙江",
					"subTitle6": "上海淞铁库/永钢厂提",
				}, {
					zy: 2,
					"title": "日昇泰",
					"name": "江苏日昇泰供应链有限公司",
					"time": "",
					"subTitle": "马长江",
					"subTitle2": "螺纹钢/盘螺",
					"subTitle3": "(12-32)*9",
					"subTitle4": "HRB400E",
					"subTitle5": "江苏扬州",
					"subTitle6": "扬州港",
				}, ],
				regionList: ['江苏', '浙江', '上海'],
			};
		},
		created() {

		},
		methods: {

			tabRegion(index, item) {
				this.recommendTab = index;
				console.log(this.recommendTab, '1111')
				if (this.recommendTab == 0) {
					this.companyList = [{
						zy: 1,
						"title": "宝汇",
						"name": "浙江宝汇大宗供应链有限公司",
						"num": '4.35',
						"subTitle": "萍钢/永钢",
						"subTitle2": "螺纹钢/盘螺",
						"subTitle3": "(12-32)*9",
						"subTitle4": "HRB400E/HRB500E",
						"subTitle5": "江苏",
						"subTitle6": "南京中储库",
					}, {
						zy: 1,
						"title": "晨华",
						"name": "浙江晨华供应链有限公司",
						"num": '3.8',
						"subTitle": "萍九钢/永钢/中天",
						"subTitle2": "螺纹钢/盘螺",
						"subTitle3": "(12-32)*9",
						"subTitle4": "HRB400/HRB400E",
						"subTitle5": "上海/江苏/浙江",
						"subTitle6": "上海淞铁库/永钢厂提",
					}, {
						zy: 2,
						"title": "日昇泰",
						"name": "江苏日昇泰供应链有限公司",
						"subTitle": "马长江",
						"subTitle2": "螺纹钢/盘螺",
						"subTitle3": "(12-32)*9",
						"subTitle4": "HRB400E",
						"subTitle5": "江苏扬州",
						"subTitle6": "扬州港",
					}]
				} else if (this.recommendTab == 1) {
					this.companyList = [{
						zy: 1,
						"title": "宝汇",
						"name": "浙江宝汇大宗供应链有限公司",
						"num": '1.5',
						"subTitle": "宁钢",
						"subTitle2": "热轧卷板",
						"subTitle3": "(2.0-5.75)*1250*C",
						"subTitle4": "Q235B/Q355B",
						"subTitle5": "宁波",
						"subTitle6": "九广库/金盛库",

					}, {
						zy: 1,
						"title": "超链",
						"name": "浙江超链供应链有限公司",
						"num": '9.35',
            "subTitle": "萍九钢/永钢/中天",
            "subTitle2": "螺纹钢/盘螺",
            "subTitle3": "(12-32)*9",
            "subTitle4": "HRB400/HRB400E",
            "subTitle5": "上海/江苏/浙江",
            "subTitle6": "上海淞铁库/永钢厂提",
					}, {
						zy: 2,
						"title": "华盈",
						"name": "广西建工华盈实业有限公司",
						"subTitle": "中天/永钢",
						"subTitle2": "螺纹钢/盘螺",
						"subTitle3": "(12-32)*9",
						"subTitle4": "HRB400E/HRB500E",
						"subTitle5": "杭州",
						"subTitle6": "常州中心库",
					}, ]
				} else if (this.recommendTab == 2) {
					this.companyList = [{
						zy: 2,
						"title": "沪轩",
						"name": "上海沪轩贸易有限公司",
						"subTitle": "萍九钢",
						"subTitle2": "螺纹钢/盘螺",
						"subTitle3": "(12-32)*9",
						"subTitle4": "HRB400E",
						"subTitle5": "上海",
						"subTitle6": "上海淞铁库",

					}, {
						zy: 2,
						"title": "宁领",
						"name": "上海宁领实业有限公司",
						"subTitle": "萍九钢",
						"subTitle2": "螺纹钢/盘螺",
						"subTitle3": "(12-32)*9",
						"subTitle4": "HRB400E",
						"subTitle5": "上海",
						"subTitle6": "上海淞铁库",
					}]
				}
			},
		},
		computed: {

		},
	};
</script>

<style lang="scss">
	.index-recommend {
		width: 1200px;
		background-color: #fff;
		margin: 0px auto;
		padding-bottom: 50px;
	}

	.index-recommend .recommend-nav {
		display: flex;
		width: 1200px;
		align-items: center;
		justify-content: space-between;
		margin: 30px auto;
		margin-top: 0px;
		box-sizing: border-box;
	}

	.index-recommend .recommend-nav .recommend-title {
		display: flex;
		align-items: center;
		margin-right: 30px;
		width: 188px;
		caret-color: rgba(0, 0, 0, 0);
		cursor: default;
	}


	.index-recommend .recommend-nav .recommend-title p {
		font-family: SourceHanSansCN, SourceHanSansCN;
		font-weight: 600;
		font-size: 18px;
		color: #000102;
		line-height: 26px;
		text-align: left;
		font-style: normal;
	}

	.index-recommend .recommend-nav .recommend-tab {
		display: flex;
		width: 600px;
		justify-content: flex-end;
		align-items: center;
	}

	.index-recommend .recommend-nav .recommend-tab .recommend-item {
		width: 80px;
		height: 30px;
		color: #000102;
		display: flex;
		align-items: center;
		justify-content: center;
		caret-color: rgba(0, 0, 0, 0);
		cursor: pointer;
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 14px;
		font-style: normal;
		margin-left: 50px;
	}

	.index-recommend .recommend-nav .recommend-tab .recommend-item.active {
		width: 80px;
		height: 30px;
		border-radius: 15px;
		border: 1px solid #0285ED;
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 14px;
		color: #0285ED;
		font-style: normal;
		text-align: center;
		justify-content: center;
	}

	.index-recommend .recommend-nav .recommend-mark {
		width: 190.93px;
		display: flex;
		font-size: 14px;
		color: #999999ff;
		align-items: center;
		justify-content: flex-end;
		caret-color: rgba(0, 0, 0, 0);
		cursor: pointer;
	}

	.index-recommend .recommend-nav .recommend-mark img {
		width: 5.96px;
		height: 5.96px;
		margin-left: 4px;
	}

	.index-recommend .index-recommend-list {
		width: 1200px;
		display: flex;
	}

	.index-recommend .index-recommend-list .recommend-list-item {
		width: 384px;
		height: 208px;
		background: #FFFFFF;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
		border-radius: 4px;
		margin-right: 48px;
		border: 1px solid #FFFFFF;
		cursor: pointer;
	}

	.index-recommend .index-recommend-list .recommend-list-item:hover {
		border-radius: 4px;
		border: 1px solid #0285ED;
	}

	.index-recommend .index-recommend-list .recommend-list-item:last-child {
		margin-right: 0px;
	}

	.recommend-list-item .list-item-head {
		height: 60px;
		display: flex;
		padding: 20px;
	}

	.recommend-list-item .list-item-head .list-item-head-left-img {
		width: 60px;
		height: 60px;
		background: #FBD889;
		border-radius: 4px;
		font-family: SourceHanSansCN, SourceHanSansCN;
		font-weight: 500;
		font-size: 18px;
		color: #FFFFFF;
		line-height: 60px;
		text-align: center;
		font-style: normal;
	}

	.recommend-list-item .list-item-head .list-item-head-left-text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding-left: 10px;
	}

	.recommend-list-item .list-item-head .list-item-head-left-text .list-item-head-left-text-name {
		font-family: SourceHanSansCN, SourceHanSansCN;
		font-weight: 600;
		font-size: 18px;
		color: #000102;
		line-height: 26px;
		text-align: left;
		font-style: normal;
	}

	.recommend-list-item .list-item-head .list-item-head-left-text .list-item-head-left-text-name2 {
		font-family: SourceHanSansCN, SourceHanSansCN;
		font-weight: 400;
		font-size: 12px;
		color: #999999;
		line-height: 17px;
		text-align: left;
		font-style: normal;
		margin-top: 9px;
		display: flex;
		align-items: center;
	}

	.recommend-list-item .list-item-footer {
		padding: 0px 20px;
		display: flex;
		flex-direction: column;
		padding-bottom: 20px;
	}

	.footerItem {
		height: 90px;
		display: flex;
		flex-direction: column;
		justify-content: center;

	}

	.footerHead {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-family: SourceHanSansCN, SourceHanSansCN;
		font-weight: 400;
		font-size: 14px;
		color: #000102;
		line-height: 20px;
		text-align: right;
		margin-top: 10px;
		font-style: normal;
	}

	.footerItem span {
		margin-right: 14px;
	}

	.footerTitle {
		margin-top: 18px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-family: SourceHanSansCN, SourceHanSansCN;
		font-weight: 400;
		font-size: 14px;
		color: #999999;
		line-height: 20px;
		text-align: right;
		font-style: normal;
	}
</style>
<template>
	<div class="homeIndex">
		<headerNav :activeIndex="1" />
		<div :class="this.redStatus?'redBag':this.twoStatus?'threeBag':this.threeStatus?'blueBag':''">
			<div class="ddmg-banner">
				<el-carousel :interval="3000" arrow="never" autoplay style="width: 1200px; height: 402px;"
					@change="bannerChange">
					<el-carousel-item style="width: 100%; height: 402px">
						<img src="../../assets/img/banner1.png" style="width: 100%; height:402px; cursor: pointer"
							alt="至刚网" />
					</el-carousel-item>
					<el-carousel-item style="width: 100%; height: 402px">
						<img src="../../assets/img/banner.png" style="width: 100%; height:402px; cursor: pointer" alt="至刚网" />
					</el-carousel-item>
          <el-carousel-item style="width: 100%; height: 402px">
            <img src="../../assets/img/banner2.png" style="width: 100%; height:402px; cursor: pointer" alt="至刚网" />
          </el-carousel-item>
				</el-carousel>
			</div>
		</div>
		<div class="realTime">
			<div class="echarts-title">实时数据</div>
			<div class="ddmg-index-login">
				<div class="login-box-scoll">
					<scroll class="seamless-warp" :data="transactionOrderList" :class-option="classOption">
						<ul>
							<li v-for="(item, index) in transactionOrderList" :key="index">
								<div class="right-item-title">
									<p>{{ item.company }}</p>
								</div>
								<div class="right-item-kg">
									<p style="color:#0285ED;">{{ item.sale }}吨</p>
								</div>
								<div class="right-item-time">
									<p>{{ item.time }}</p>
								</div>
							</li>
						</ul>
					</scroll>
				</div>
			</div>
		</div>
		<resources />
		<recommend />
		<platformAndCooperation />
		<footerNav />
	</div>
</template>

<script>
	import scroll from "vue-seamless-scroll";
	import resources from "./components/resources.vue";
	import recommend from "./components/recommend.vue";
	import platformAndCooperation from "./components/platformAndCooperation.vue";
	import footerNav from "../../components/footerNav.vue";
	import headerNav from "../../components/headerNav.vue";
	import dayjs from "dayjs";
	export default {
		name: "home",
		components: {
			scroll,
			resources,
			recommend,
			platformAndCooperation,
			footerNav,
			headerNav
		},
		data() {
			return {
				redStatus: true,
        twoStatus: false,
        threeStatus: false,
				transactionOrderList: [{
						"company": "上海钢银电子商务股份有限公司",
						"sale": "40.054"
					},
					{
						"company": "上海找钢网信息科技股份有限公司",
						"sale": "39.909"
					},
					{
						"company": "江苏易沅电子商务有限公司",
						"sale": "39.514"
					},
					{
						"company": "南京祥誉电子商务有限公司",
						"sale": "38.82"
					},
					{
						"company": "上海中荆实业有限公司",
						"sale": "38.624"
					},
					{
						"company": "江阴市恒晟达金属贸易有限公司",
						"sale": "38.624"
					},
					{
						"company": "常熟市鑫扬钢铁贸易有限公司",
						"sale": "38.415"
					},
					{
						"company": "南京润佳五金电机有限公司",
						"sale": "38.202"
					},
					{
						"company": "唯钢实业（上海）有限公司",
						"sale": "37.966"
					},
					{
						"company": "南通飞辰金属材料有限公司",
						"sale": "37.658"
					},
					{
						"company": "常熟市龙祥金属材料销售有限公司",
						"sale": "37.578"
					},
					{
						"company": "江苏卓楚智能科技有限公司",
						"sale": "37.268"
					},
					{
						"company": "上海潭慧实业有限公司",
						"sale": "36.319"
					},
					{
						"company": "芜湖安达物资有限责任公司",
						"sale": "36.289"
					},
					{
						"company": "南京钢璟贸易有限公司",
						"sale": "36.167"
					},
					{
						"company": "金湖县金储物流有限公司",
						"sale": "36.125"
					},
					{
						"company": "上海吉言利金属材料有限公司",
						"sale": "36.068"
					},
					{
						"company": "南京灏之瑞物资有限公司",
						"sale": "35.733"
					},
					{
						"company": "南京亿盛达贸易有限公司",
						"sale": "35.486"
					},
					{
						"company": "南京巨舟贸易有限公司",
						"sale": "35.35"
					},
					{
						"company": "江苏亿林供应链管理有限公司",
						"sale": "35.052"
					},
					{
						"company": "南京鹏亦源钢铁贸易有限公司",
						"sale": "34.78"
					},
					{
						"company": "苏州颜欧璟贸易有限公司",
						"sale": "34.71"
					},
					{
						"company": "南京荣誉都建材有限公司",
						"sale": "34.53"
					},
					{
						"company": "江苏省农垦金属材料有限公司",
						"sale": "34.499"
					},
					{
						"company": "广州市扬山金属物资有限公司",
						"sale": "34.37"
					},
					{
						"company": "江阴鑫晟宏贸易有限公司",
						"sale": "34.316"
					},
					{
						"company": "中获国际贸易（上海）有限公司",
						"sale": "34.15"
					},
					{
						"company": "上海展烨金属材料有限公司",
						"sale": "34.109"
					},
					{
						"company": "江苏凯嗣伊供应链管理有限公司",
						"sale": "34.104"
					},
					{
						"company": "无锡洪禄经贸有限公司",
						"sale": "34.095"
					},
					{
						"company": "宁波高欣臻裕物资有限公司",
						"sale": "34.057"
					},
					{
						"company": "乌鲁木齐华源正泰贸易有限公司",
						"sale": "34.017"
					},
					{
						"company": "江苏欣海建材有限公司",
						"sale": "34.017"
					},
					{
						"company": "厦门国贸集团股份有限公司",
						"sale": "34"
					},
					{
						"company": "南京欣毅成贸易有限公司",
						"sale": "33.983"
					},
					{
						"company": "镇江文兴钢铁有限公司",
						"sale": "33.982"
					},
					{
						"company": "宁波中存国际贸易有限公司",
						"sale": "33.979"
					},
					{
						"company": "上海岐睿商贸有限公司",
						"sale": "33.968"
					},
					{
						"company": "镇江乾正盛金属材料有限公司",
						"sale": "33.966"
					},
					{
						"company": "上海柳亦实业有限公司",
						"sale": "33.881"
					},
					{
						"company": "杭州锦晟钢铁有限公司",
						"sale": "33.813"
					},
					{
						"company": "苏州恒恩金属材料有限公司",
						"sale": "33.813"
					},
					{
						"company": "上海皓昱金属材料有限公司",
						"sale": "33.779"
					},
					{
						"company": "上海和飞贸易有限公司",
						"sale": "33.765"
					},
					{
						"company": "上海昊新科技有限公司",
						"sale": "33.718"
					},
					{
						"company": "山东泽钢供应链有限公司",
						"sale": "33.705"
					},
					{
						"company": "巨福供应链（张家港）有限公司",
						"sale": "33.598"
					},
					{
						"company": "上海聚近实业有限公司",
						"sale": "33.582"
					},
					{
						"company": "江苏源旺贸易有限公司",
						"sale": "33.577"
					},
					{
						"company": "上海吾邦钢铁有限公司",
						"sale": "33.572"
					},
					{
						"company": "江苏仟舟立贸易有限公司",
						"sale": "33.572"
					},
					{
						"company": "南京浦业建材有限公司",
						"sale": "33.544"
					},
					{
						"company": "常州永固新物资有限公司",
						"sale": "33.264"
					},
					{
						"company": "常州嘉企金属材料有限公司",
						"sale": "33.133"
					},
					{
						"company": "上海钟钢物资有限公司",
						"sale": "33.035"
					},
					{
						"company": "安徽众乘物资有限公司",
						"sale": "33.029"
					},
					{
						"company": "南京继航金属材料有限公司",
						"sale": "32.99"
					},
					{
						"company": "扬州瀚华物资有限公司",
						"sale": "32.76"
					},
					{
						"company": "上海坦为贸易有限公司",
						"sale": "32.76"
					},
					{
						"company": "江苏智园时代科技有限公司",
						"sale": "32.76"
					},
					{
						"company": "南京大利贸易有限公司",
						"sale": "32.748"
					},
					{
						"company": "南京乘帆物资有限公司",
						"sale": "32.722"
					},
					{
						"company": "浙江绍兴惠源工贸有限公司",
						"sale": "32.721"
					},
					{
						"company": "湖南炬材工贸有限公司",
						"sale": "32.695"
					},
					{
						"company": "南京佰兆钰贸易有限公司",
						"sale": "32.682"
					},
					{
						"company": "青岛海东实业有限公司",
						"sale": "32.654"
					},
					{
						"company": "上海悦雯贸易有限公司",
						"sale": "32.638"
					},
					{
						"company": "扬州耀通金属物资有限公司",
						"sale": "32.62"
					},
					{
						"company": "江苏润昊建材有限公司",
						"sale": "32.575"
					},
					{
						"company": "江苏瑞美嘉贸易有限公司",
						"sale": "32.574"
					},
					{
						"company": "南京源创供应链管理有限公司",
						"sale": "32.564"
					},
					{
						"company": "南京鸣达供应链管理有限公司",
						"sale": "32.485"
					},
					{
						"company": "上海俱臻国际贸易有限公司",
						"sale": "32.472"
					},
					{
						"company": "上海业鑫信息科技有限公司",
						"sale": "32.298"
					},
					{
						"company": "南京群勇物资有限公司",
						"sale": "32.122"
					},
					{
						"company": "南京富诚祥金属材料有限公司",
						"sale": "32.115"
					},
					{
						"company": "南京启致辉物资有限公司",
						"sale": "32.094"
					},
					{
						"company": "上海瑞河贸易有限公司",
						"sale": "31.987"
					},
					{
						"company": "无锡辉聚来贸易有限公司",
						"sale": "31.976"
					},
					{
						"company": "南京港建金属材料有限公司 ",
						"sale": "31.948"
					},
					{
						"company": "南京铄康金属材料有限公司",
						"sale": "31.888"
					},
					{
						"company": "南京传之亮贸易有限公司",
						"sale": "31.848"
					},
					{
						"company": "芜湖市昌新材料有限公司",
						"sale": "31.806"
					},
					{
						"company": "宁波金辰宝品钢铁有限公司",
						"sale": "31.791"
					},
					{
						"company": "上海门真供应链管理有限公司",
						"sale": "31.736"
					},
					{
						"company": "江苏一铭供应链有限公司",
						"sale": "31.68"
					},
					{
						"company": "苏州振诚金属材料有限公司",
						"sale": "31.68"
					},
					{
						"company": "南京龙禹工贸有限公司",
						"sale": "31.653"
					},
					{
						"company": "上海九为物资有限公司",
						"sale": "31.578"
					},
					{
						"company": "上海珠钢贸易有限公司",
						"sale": "31.558"
					},
					{
						"company": "上海思远国际贸易有限公司",
						"sale": "31.536"
					},
					{
						"company": "宁波麦乐金属有限公司",
						"sale": "31.522"
					},
					{
						"company": "宁波炯盛钢铁贸易有限公司",
						"sale": "31.515"
					},
					{
						"company": "安徽嘉诚达供应链管理有限公司",
						"sale": "31.494"
					},
					{
						"company": "镇江万潮建材有限公司",
						"sale": "31.445"
					},
					{
						"company": "宁波泽义钢材科技有限公司",
						"sale": "31.374"
					},
					{
						"company": "上海集惠金属材料有限公司",
						"sale": "31.174"
					},
					{
						"company": "南京长青钢铁有限公司",
						"sale": "31.171"
					},
					{
						"company": "宁波启典供应链有限公司",
						"sale": "31.067"
					},
					{
						"company": "中鼎供应链管理（上海）有限公司",
						"sale": "31.006"
					},
					{
						"company": "上海惠捷实业有限公司",
						"sale": "30.687"
					},
					{
						"company": "上海朗昊供应链管理有限公司 ",
						"sale": "30.51"
					},
					{
						"company": "苏州鼎泰鑫供应链有限公司",
						"sale": "30.111"
					},
					{
						"company": "苏州丰圣隆贸易有限公司",
						"sale": "29.98"
					},
					{
						"company": "温州百友金属材料有限公司",
						"sale": "29.961"
					},
					{
						"company": "盱眙文邦建材有限公司",
						"sale": "29.95"
					},
					{
						"company": "江苏原创建设实业有限公司",
						"sale": "29.94"
					},
					{
						"company": "镇江丰田建材有限公司",
						"sale": "29.93"
					},
					{
						"company": "上海椒钢贸易有限公司",
						"sale": "29.86"
					},
					{
						"company": "江苏一格供应链管理有限公司",
						"sale": "29.84"
					},
					{
						"company": "北京昌韵恒通商贸有限公司",
						"sale": "29.81"
					},
					{
						"company": "常熟市铭盛钢铁贸易有限公司",
						"sale": "29.796"
					},
					{
						"company": "无锡市方储贸易有限公司",
						"sale": "29.794"
					},
					{
						"company": "南京鹏亦通贸易有限公司",
						"sale": "29.79"
					},
					{
						"company": "常熟宁源物资贸易有限公司",
						"sale": "29.778"
					},
					{
						"company": "南京钢尊贸易有限公司",
						"sale": "29.775"
					},
					{
						"company": "江苏苏地国际贸易有限公司",
						"sale": "29.77"
					},
					{
						"company": "镇江信华安泰电子商务有限公司",
						"sale": "29.76"
					},
					{
						"company": "江苏天之润供应链管理有限公司",
						"sale": "29.735"
					},
					{
						"company": "无锡新区臻搏经贸有限公司",
						"sale": "29.726"
					},
					{
						"company": "上海易融供应链管理有限公司",
						"sale": "29.71"
					},
					{
						"company": "上海惠瑜实业有限公司",
						"sale": "29.71"
					},
					{
						"company": "湖北晶通钢铁供应链有限公司",
						"sale": "29.71"
					},
					{
						"company": "上海优赐实业有限公司",
						"sale": "29.68"
					},
					{
						"company": "句容市巨旺建材贸易有限公司",
						"sale": "29.668"
					},
					{
						"company": "江苏隆超汽配有限公司",
						"sale": "29.66"
					},
					{
						"company": "南京奋言商贸有限公司",
						"sale": "29.59"
					},
					{
						"company": "上海德开实业有限公司",
						"sale": "29.58"
					},
					{
						"company": "湖南宝盛供应链管理有限公司",
						"sale": "29.566"
					},
					{
						"company": "南京双诺贸易有限公司",
						"sale": "29.561"
					},
					{
						"company": "南京庆平贸易有限公司",
						"sale": "29.504"
					},
					{
						"company": "河南中钢网电子商务有限公司",
						"sale": "29.5"
					},
					{
						"company": "芜湖市宝菁商贸有限责任公司",
						"sale": "29.482"
					},
					{
						"company": "江苏钢之家电子商务有限公司",
						"sale": "29.48"
					},
					{
						"company": "上海昌导钢铁贸易有限公司",
						"sale": "29.474"
					},
					{
						"company": "上海潮广金属材料有限公司",
						"sale": "29.441"
					},
					{
						"company": "上海一也国际贸易有限公司",
						"sale": "29.432"
					},
					{
						"company": "江苏长生旺国际贸易有限公司",
						"sale": "29.35"
					},
					{
						"company": "扬州城和金属材料有限公司",
						"sale": "29.34"
					},
					{
						"company": "上海达玲供应链科技有限公司",
						"sale": "29.27"
					},
					{
						"company": "安徽建工建材科技集团有限公司合肥分公司",
						"sale": "29.122"
					},
					{
						"company": "芜湖市飞富钢铁贸易有限公司",
						"sale": "29.02"
					},
					{
						"company": "张家港沐泽贸易有限公司",
						"sale": "28.787"
					},
					{
						"company": "宜兴市福瑞洪贸易有限公司",
						"sale": "28.165"
					},
					{
						"company": "江苏富鑫钢铁有限公司",
						"sale": "28.144"
					},
					{
						"company": "浙江中邦钢铁有限公司",
						"sale": "28.102"
					},
					{
						"company": "中盾供应链管理有限公司",
						"sale": "28.083"
					},
					{
						"company": "南京堂可贸易有限公司",
						"sale": "28.037"
					},
					{
						"company": "南京三尚贸易有限公司",
						"sale": "27.93"
					},
					{
						"company": "上海周墩物资有限公司",
						"sale": "27.895"
					},
					{
						"company": "常熟市龙飞物资贸易有限公司",
						"sale": "27.4"
					},
					{
						"company": "江苏兴诚旺供应链管理有限公司",
						"sale": "26.885"
					},
					{
						"company": "南京航尔申金属材料有限公司",
						"sale": "26.87"
					},
					{
						"company": "镇江君瑞物资贸易有限公司",
						"sale": "26.59"
					},
					{
						"company": "上海映霖贸易有限公司",
						"sale": "26.582"
					},
					{
						"company": "南京双成供应链管理有限公司",
						"sale": "26.502"
					},
					{
						"company": "江苏鼎信供应链有限公司",
						"sale": "26.03"
					},
					{
						"company": "上海华寅实业有限公司",
						"sale": "25.464"
					},
					{
						"company": "南京宇浩物资有限公司",
						"sale": "25.299"
					},
					{
						"company": "安徽好运来能源科技有限公司",
						"sale": "25.125"
					},
					{
						"company": "无锡申港联盟物资有限公司",
						"sale": "24.81"
					},
					{
						"company": "上海发营实业有限公司",
						"sale": "24.755"
					},
					{
						"company": "上海神越贸易有限公司",
						"sale": "24.541"
					},
					{
						"company": "辽宁信实钢铁物资有限公司",
						"sale": "23.992"
					},
					{
						"company": "武汉市再生资源开发利用有限公司",
						"sale": "23.96"
					},
					{
						"company": "南京泊喜宝建筑安装工程有限公司",
						"sale": "23.955"
					},
					{
						"company": "安徽宝东供应链管理有限公司",
						"sale": "23.912"
					},
					{
						"company": "上海达庭实业有限公司",
						"sale": "23.549"
					},
					{
						"company": "无锡市银凯钢铁贸易有限公司",
						"sale": "23.279"
					},
					{
						"company": "南京卡佳贸易有限公司",
						"sale": "23.182"
					},
					{
						"company": "南京晏和金属材料有限公司",
						"sale": "23.11"
					},
					{
						"company": "青岛经控智慧物流有限公司",
						"sale": "23.021"
					},
					{
						"company": "上海宝翔物资有限公司",
						"sale": "22.939"
					},
					{
						"company": "江苏达钰贸易有限公司",
						"sale": "22.828"
					},
					{
						"company": "宁波星铂贸易有限公司",
						"sale": "22.64"
					},
					{
						"company": "宁波济浦钢铁有限公司",
						"sale": "22.64"
					},
					{
						"company": "上海博立钢铁有限公司",
						"sale": "22.395"
					},
					{
						"company": "宁波建方钢铁有限公司",
						"sale": "22.014"
					},
					{
						"company": "宁波莱拓钢铁贸易有限公司",
						"sale": "21.912"
					},
					{
						"company": "上海夏庚金属材料有限公司",
						"sale": "21.747"
					},
					{
						"company": "宁波市新远发钢铁有限公司",
						"sale": "20.81"
					},
					{
						"company": "上海协拓实业有限公司",
						"sale": "20.673"
					},
					{
						"company": "东台市兴东吴钢材贸易有限公司",
						"sale": "20.607"
					},
					{
						"company": "江阴市中缘国际贸易有限公司",
						"sale": "20.48"
					},
					{
						"company": "上海钢钢好实业有限公司",
						"sale": "20.342"
					},
					{
						"company": "上海君粤实业有限公司",
						"sale": "19.98"
					},
					{
						"company": "浙江甬盟信息科技发展有限公司",
						"sale": "19.956"
					},
					{
						"company": "南京浦钛达建材贸易有限公司",
						"sale": "19.917"
					},
					{
						"company": "上海榛优贸易有限公司",
						"sale": "19.239"
					},
					{
						"company": "江苏荣诚物资贸易有限公司",
						"sale": "19.184"
					},
					{
						"company": "南京联嘉商贸有限公司",
						"sale": "19.168"
					},
					{
						"company": "杭州宇睦钢铁有限公司",
						"sale": "18.677"
					},
					{
						"company": "上海阔冶实业有限公司",
						"sale": "18.432"
					},
					{
						"company": "浙江中荣实业有限公司",
						"sale": "18.427"
					},
					{
						"company": "江苏中企鑫钢铁供应链管理有限公司",
						"sale": "16.749"
					},
					{
						"company": "南京鹏典物资贸易有限公司",
						"sale": "16.69"
					},
					{
						"company": "上海艺丹圣供应链管理有限公司",
						"sale": "16.583"
					},
					{
						"company": "宁波启超贸易有限公司",
						"sale": "16.3"
					},
					{
						"company": "镇江浩博金属材料有限公司",
						"sale": "16.028"
					},
					{
						"company": "苏州显卓金属材料有限公司",
						"sale": "16.018"
					},
					{
						"company": "上海旭拓工贸有限公司",
						"sale": "16.016"
					},
					{
						"company": "镇江市汇丰金属材料有限公司",
						"sale": "15.97"
					},
					{
						"company": "无锡宝鼎供应链管理有限公司",
						"sale": "15.928"
					},
					{
						"company": "上海远瞻企业发展有限公司",
						"sale": "15.805"
					},
					{
						"company": "南京宁钢物资有限公司",
						"sale": "15.795"
					},
					{
						"company": "南京勤创物资有限公司",
						"sale": "15.684"
					},
					{
						"company": "上海程肯金属材料有限公司",
						"sale": "14.704"
					},
					{
						"company": "苏州醒狮物资有限公司",
						"sale": "14.462"
					},
					{
						"company": "上海鑫随实业有限公司",
						"sale": "14.07"
					},
					{
						"company": "杭州顺骐供应链有限公司",
						"sale": "14.007"
					},
					{
						"company": "苏州林峰强物资贸易有限公司",
						"sale": "13.987"
					},
					{
						"company": "上海享栩实业有限公司",
						"sale": "13.932"
					},
					{
						"company": "扬州飞宏达物资有限公司",
						"sale": "13.929"
					},
					{
						"company": "镇江恒展建材有限公司",
						"sale": "13.859"
					},
					{
						"company": "上海弘贯实业有限公司",
						"sale": "13.475"
					},
					{
						"company": "江苏东晟物资贸易有限公司",
						"sale": "12.09"
					},
					{
						"company": "无锡市昱天霖贸易有限公司",
						"sale": "11.934"
					},
					{
						"company": "南京佰利昌贸易有限公司",
						"sale": "11.88"
					},
					{
						"company": "马鞍山市奎宁钢材贸易有限公司",
						"sale": "11.379"
					},
					{
						"company": "上海铜仓贸易有限公司",
						"sale": "11.319"
					},
					{
						"company": "南京聚全金属材料有限公司",
						"sale": "11.054"
					},
					{
						"company": "上海人民包装有限公司",
						"sale": "11.031"
					},
					{
						"company": "南京攀云物资有限公司",
						"sale": "10.503"
					},
					{
						"company": "江苏恒栖金属材料有限公司",
						"sale": "10.06"
					},
					{
						"company": "浙江钢有商贸有限公司",
						"sale": "9.866"
					},
					{
						"company": "无锡天火供应链管理有限公司",
						"sale": "41.118"
					},
					{
						"company": "上海昊涛贸易有限公司",
						"sale": "40.854"
					},
					{
						"company": "上海玮岐贸易有限公司",
						"sale": "40.824"
					},
					{
						"company": "江苏烨之森商贸有限公司",
						"sale": "40.205"
					},
					{
						"company": "江苏铭鼎格供应链管理有限公司",
						"sale": "40.054"
					},
					{
						"company": "上海奕祥金属材料有限公司",
						"sale": "39.909"
					},
					{
						"company": "广州市长里贸易有限公司",
						"sale": "39.514"
					},
					{
						"company": "常熟市裕江物资有限责任公司",
						"sale": "38.82"
					},
					{
						"company": "江苏沂拓新材料科技有限公司",
						"sale": "38.624"
					},
					{
						"company": "上海京玖金属材料有限公司",
						"sale": "38.624"
					},
					{
						"company": "上海盈行实业有限公司",
						"sale": "38.415"
					},
					{
						"company": "上海福铁龙工程科技有限公司",
						"sale": "38.202"
					},
					{
						"company": "扬州运之鑫金属材料有限公司",
						"sale": "37.966"
					},
					{
						"company": "滁州佑源电子商务有限公司",
						"sale": "37.658"
					},
					{
						"company": "张家港保税区三久国际贸易有限公司",
						"sale": "37.578"
					},
					{
						"company": "无锡莱嘉达金属新材料有限公司",
						"sale": "37.268"
					},
					{
						"company": "上海匡誉实业有限公司",
						"sale": "36.319"
					},
					{
						"company": "上海笙承实业有限公司",
						"sale": "36.289"
					},
					{
						"company": "苏州九城贸易有限公司",
						"sale": "36.167"
					},
					{
						"company": "上海展志钢材加工配送有限公司",
						"sale": "36.125"
					},
					{
						"company": "江阴市顺驰钢铁贸易有限公司",
						"sale": "36.068"
					},
					{
						"company": "南京精润电子商务有限公司",
						"sale": "35.733"
					},
					{
						"company": "南京鑫纪元贸易有限公司",
						"sale": "35.486"
					},
					{
						"company": "无锡唐创钢铁贸易有限公司",
						"sale": "35.35"
					},
					{
						"company": "江苏源路贸易有限公司",
						"sale": "35.052"
					},
					{
						"company": "南京皓元金属材料有限公司",
						"sale": "34.78"
					},
					{
						"company": "上海建善实业有限公司",
						"sale": "34.71"
					},
					{
						"company": "安徽诚畔商贸有限公司",
						"sale": "34.53"
					},
					{
						"company": "江阴瑞贝物资有限公司",
						"sale": "34.499"
					},
					{
						"company": "日照小马奔腾供应链有限公司",
						"sale": "34.37"
					},
					{
						"company": "浙江威廉贸易有限公司",
						"sale": "34.316"
					},
					{
						"company": "无锡市金超钢贸有限公司",
						"sale": "34.15"
					},
					{
						"company": "滁州腾启商贸有限公司",
						"sale": "34.109"
					},
					{
						"company": "上海嘉士莱实业发展有限公司",
						"sale": "34.104"
					},
					{
						"company": "南京沣聚江金属材料有限公司",
						"sale": "34.095"
					},
					{
						"company": "上海世淳国际贸易有限公司",
						"sale": "34.057"
					},
					{
						"company": "马鞍山市领科金属材料贸易有限公司",
						"sale": "34.017"
					},
					{
						"company": "南京金明建材贸易有限公司",
						"sale": "34.017"
					},
					{
						"company": "江阴市冠华金属贸易有限公司",
						"sale": "34"
					},
					{
						"company": "上海崇铁物资有限公司",
						"sale": "33.983"
					},
					{
						"company": "上海商扬钢铁有限公司",
						"sale": "33.982"
					},
					{
						"company": "上海聿锦实业有限公司",
						"sale": "33.979"
					},
					{
						"company": "上海中焦企业发展有限公司",
						"sale": "33.968"
					},
					{
						"company": "无锡致高建材有限公司",
						"sale": "33.966"
					},
					{
						"company": "宁波宝都商贸有限公司",
						"sale": "33.881"
					},
					{
						"company": "常州国业商贸有限公司",
						"sale": "9.602"
					},
					{
						"company": "江苏三知元新材料科技有限公司",
						"sale": "9.584"
					},
					{
						"company": "泰兴市国恒金属材料有限公司",
						"sale": "9.564"
					},
					{
						"company": "上海尹灿实业有限公司",
						"sale": "9.243"
					},
					{
						"company": "扬州同创金属有限公司",
						"sale": "8.289"
					},
					{
						"company": "南京钢捷金属材料有限公司 ",
						"sale": "8.15"
					},
					{
						"company": "日照世纪源泉贸易有限公司",
						"sale": "8.122"
					},
					{
						"company": "淮安市永拓贸易有限公司",
						"sale": "8.104"
					},
					{
						"company": "上海全来钢铁销售有限公司",
						"sale": "7.964"
					},
					{
						"company": "华虎钢铁集团有限公司",
						"sale": "7.884"
					},
					{
						"company": "上海立际实业有限公司",
						"sale": "7.295"
					},
					{
						"company": "上海济方智能科技有限公司",
						"sale": "7.21"
					},
					{
						"company": "日照鸿光贸易有限公司",
						"sale": "6.765"
					},
					{
						"company": "南京钢晟贸易有限公司",
						"sale": "6.757"
					},
					{
						"company": "上海均衡物资有限公司",
						"sale": "6.107"
					},
					{
						"company": "江苏和蕊祥贸易有限公司",
						"sale": "6.03"
					},
					{
						"company": "中储南京物流有限公司经销分公司",
						"sale": "5.976"
					},
					{
						"company": "南京佳拓新材料科技有限公司",
						"sale": "5.364"
					},
					{
						"company": "南京昇鑫浩机电科技有限公司",
						"sale": "5.216"
					},
					{
						"company": "浙江超链供应链有限公司",
						"sale": "5.165"
					},
					{
						"company": "南京华夏钢铁有限公司",
						"sale": "5.04"
					},
					{
						"company": "安徽鑫沁发商贸有限公司",
						"sale": "4.929"
					},
					{
						"company": "南京热创金属材料有限公司",
						"sale": "4.796"
					},
					{
						"company": "南京灵苏金属材料有限公司",
						"sale": "4.697"
					},
					{
						"company": "杭州跃堃国际贸易有限公司",
						"sale": "4.694"
					},
					{
						"company": "常州宏帛尔贸易有限公司",
						"sale": "4.61"
					},
					{
						"company": "马鞍山松鸿金属材料贸易有限公司",
						"sale": "4.244"
					},
					{
						"company": "镇江沙钢物流有限公司",
						"sale": "4.095"
					},
					{
						"company": "上海壹增建材有限公司",
						"sale": "32.575"
					},
					{
						"company": "安徽灏轩贸易有限公司",
						"sale": "32.574"
					},
					{
						"company": "北京澳隆德科技发展有限公司",
						"sale": "32.564"
					},
					{
						"company": "宿迁翔泽商贸有限公司",
						"sale": "32.485"
					},
					{
						"company": "南京杰龙物流有限公司",
						"sale": "32.472"
					},
					{
						"company": "江苏中宏钢铁贸易有限公司",
						"sale": "32.298"
					},
					{
						"company": "南京吉善嘉新材料科技有限公司",
						"sale": "32.122"
					},
					{
						"company": "创秋致远供应链管理（青岛）有限公司",
						"sale": "32.115"
					},
					{
						"company": "上海贸一电子商务有限公司",
						"sale": "32.094"
					},
					{
						"company": "无锡尚协钢铁物资有限公司",
						"sale": "31.987"
					},
					{
						"company": "南通沙隆金属材料有限公司",
						"sale": "31.976"
					},
					{
						"company": "南京中元供应链管理有限公司",
						"sale": "31.948"
					},
					{
						"company": "江苏国旻贸易有限公司",
						"sale": "31.888"
					},
					{
						"company": "合肥钢博商贸有限公司",
						"sale": "31.848"
					},
					{
						"company": "南京崇源供应链管理有限公司",
						"sale": "31.806"
					},
					{
						"company": "江阴市创来物资有限公司",
						"sale": "31.791"
					},
					{
						"company": "南京日光商贸有限责任公司",
						"sale": "31.736"
					},
					{
						"company": "南京迈钢物资有限公司",
						"sale": "31.68"
					},
					{
						"company": "上海彦恒道国际贸易有限公司",
						"sale": "31.68"
					},
					{
						"company": "上海岐泰实业有限公司",
						"sale": "31.653"
					},
					{
						"company": "南京国春金属材料有限公司",
						"sale": "31.578"
					},
					{
						"company": "上海黎震实业有限公司",
						"sale": "31.558"
					},
					{
						"company": "上海贤和商贸发展有限公司",
						"sale": "31.536"
					},
					{
						"company": "苏州晶柯润建筑材料有限公司",
						"sale": "31.522"
					},
					{
						"company": "宜兴市曙光钢材销售有限公司",
						"sale": "31.515"
					},
					{
						"company": "苏州钢茂申金属制品有限公司",
						"sale": "31.494"
					},
					{
						"company": "上海楚欧实业有限公司",
						"sale": "31.445"
					},
					{
						"company": "南京钢玖贸易有限公司",
						"sale": "31.374"
					},
					{
						"company": "江苏奔牛新能源科技有限公司",
						"sale": "31.174"
					},
					{
						"company": "上海韬明贸易有限公司",
						"sale": "31.171"
					},
					{
						"company": "湖北晶源供应链管理有限公司",
						"sale": "31.067"
					},
					{
						"company": "芜湖市晟顺金属材料有限公司",
						"sale": "31.006"
					},
					{
						"company": "凤阳县大隆金属材料有限公司",
						"sale": "30.687"
					},
					{
						"company": "南京储杰金属材料有限公司",
						"sale": "30.51"
					},
					{
						"company": "南京凡蒂翔商贸有限公司",
						"sale": "30.111"
					},
					{
						"company": "宁波商普贸易有限公司",
						"sale": "29.98"
					},
					{
						"company": "江都区久丰物资经营部",
						"sale": "29.961"
					},
					{
						"company": "重庆钢好物资有限公司",
						"sale": "29.95"
					},
					{
						"company": "上海瑞人实业有限公司",
						"sale": "29.94"
					},
					{
						"company": "宁波森东钢铁有限公司",
						"sale": "29.93"
					},
					{
						"company": "宁波旺亨贸易有限公司",
						"sale": "29.86"
					},
					{
						"company": "上海巨疆实业有限公司",
						"sale": "29.84"
					},
					{
						"company": "宁波合道贸易有限公司",
						"sale": "29.81"
					},
					{
						"company": "宁波高新区松大贸易有限公司",
						"sale": "29.796"
					},
					{
						"company": "上海承誉实业有限公司",
						"sale": "29.794"
					},
					{
						"company": "宁波鑫弘佳物资有限公司",
						"sale": "29.79"
					},
					{
						"company": "上海互钢供应链管理有限公司",
						"sale": "29.778"
					},
					{
						"company": "杭州展航实业有限公司",
						"sale": "29.775"
					},
					{
						"company": "浙江宝卷钢铁有限公司",
						"sale": "29.77"
					},
					{
						"company": "宁波宝泉贸易有限公司",
						"sale": "29.76"
					},
					{
						"company": "苏州启众贸易有限公司",
						"sale": "29.735"
					},
					{
						"company": "温州锦图金属材料有限公司",
						"sale": "29.726"
					},
					{
						"company": "杭州康达物资回收有限公司",
						"sale": "29.71"
					},
					{
						"company": "宁波信义钢板有限公司",
						"sale": "29.71"
					},
					{
						"company": "宁波扬悦物资有限公司",
						"sale": "29.71"
					}
				],
			};
		},
		computed: {
			classOption() {
				return {
					direction: 1,
					hoverStop: true,
					step: 0.15,
				};
			},
		},
		created() {
			this.transactionOrderList.map(item => {
				let randomTime = this.getRandomDateTime();
				item.time = dayjs(randomTime).format("YYYY-MM-DD");
				item.company = item.company.replace(item.company.substring(2, 6), "****")
				return item
			})
			this.transactionOrderList = this.transactionOrderList.sort((a, b) => new Date(a.time) - new Date(b.time));
		},
		methods: {
			bannerChange(index) {
				if (index == 0) {
					this.redStatus = true
          this.twoStatus = false
          this.threeStatus = false
				} else if(index == 1) {
          this.twoStatus = true
          this.redStatus = false
          this.threeStatus = false
				} else if(index == 2) {
          this.threeStatus = true
          this.redStatus = false
          this.twoStatus = false
        }
			},
			getRandomDateTime() {
				const start = dayjs().startOf('day').add(7, 'hour');
				const end = dayjs().endOf('day');
				const duration = end.diff(start);
				const randomMs = Math.random() * duration;
				return start.add(randomMs, 'milliseconds');
			},
		},
	};
</script>

<style lang="scss" scoped>
	.homeIndex {
		min-width: 100vw;
		min-height: 100vh;
		text-align: center;
	}


	.ddmg-banner {
		margin: 0px auto;
		height: 402px;
		width: 1200px;
	}

	.redBag {
		height: 402px;
		width: 100%;
		transition: background-color 0.3s;
		background-color: #810102;
	}

	.blueBag {
		height: 402px;
		width: 100%;
		transition: background-color 0.3s;
		background-color: #11132E;
	}
  .threeBag {
    height: 402px;
    width: 100%;
    transition: background-color 0.3s;
    background-color: #3972F1;
  }
	.realTime {
		width: 100%;
		padding-bottom: 20px;
		background: #F9FCFF;
	}

	.ddmg-index-login {
		margin: 30px auto;
		height: 140px;
		width: 1200px;
		overflow: hidden;
	}

	.ddmg-index-login .login-box-scoll {
		font-size: 14px;
		font-weight: 400;
		margin-left: 8px;
		overflow: hidden;
		cursor: pointer;
	}

	.ddmg-index-login .login-box-scoll ul {
		display: flex;
		flex-direction: column;
	}

	.ddmg-index-login .login-box-scoll ul li {
		display: flex;
		width: 100%;
		margin-top: 20px;
	}

	.ddmg-index-login .login-box-scoll ul li div {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
	}

	.ddmg-index-login .login-box-scoll ul li .right-item-title {
		cursor: pointer;
		color: #333333;
	}

	.ddmg-index-login .login-box-scoll ul li .right-item-title p {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		word-break: break-all;
	}

	.ddmg-index-login .login-box-scoll ul li .right-item-kg {
		margin-left: 18px;
		font-size: 14px;
		color: #333333;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.ddmg-index-login .login-box-scoll ul li .right-item-kg p {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		word-break: break-all;
	}

	.ddmg-index-login .login-box-scoll ul li .right-item-time {
		justify-content: flex-end;
		color: #999999;
	}

	.echarts-title {
		width: 1200px;
		margin: 0px auto;
		padding-top: 50px;
		text-align: left;
		font-family: SourceHanSansCN, SourceHanSansCN;
		font-weight: 600;
		font-size: 18px;
		color: #000102;
		line-height: 26px;
		font-style: normal;
		background: #F9FCFF;
	}
</style>
<!--
 * @Author: luoyan
 * @Date: 2021-10-29 15:21:18
 * @LastEditTime: 2021-11-16 14:18:17
 * @Description: file content  平台服务和合作伙伴
-->
<template>
	<div class="platcoopeer">
		<!--平台服务-->
		<div class="platform">
			<div class="platform-header">
				<div class="platform-title">
					<p>服务介绍</p>
				</div>
				<div class="platform-desc">
          利用云计算、大数据、微服务等互联网技术，整合钢铁产业链流通环节资源，为平台用户提供系列全数字化交易、金融、物流、资讯产品与服务
				</div>
			</div>
			<div class="platform-body">
        <div class="platformImg" @click="handleSelect(0)"><img src="../../../assets/img/platform-body1.png" alt="至刚网"></div>
        <div class="platformImg" @click="handleSelect(1)"><img src="../../../assets/img/platform-body2.png" alt="至刚网"></div>
        <div class="platformImg" @click="handleSelect(2)"><img src="../../../assets/img/platform-body3.png" alt="至刚网"></div>
        <div><img src="../../../assets/img/platform-body4.png" alt="至刚网"></div>
			</div>
		</div>
		<!--合作伙伴-->
		<div class="cooperation">
			<div class="platform-header">
				<div class="platform-title">
					<p>合作伙伴</p>
				</div>
				<div class="platform-desc">我们的合作商</div>
			</div>
			<div class="cooperation-body">
				<!-- 添加的图片 -->
				<div>
					<ul>
						<li><img src="../../../assets/img/hz1.png" alt="至刚网"></li>
            <li><img src="../../../assets/img/hz2.png" alt="至刚网"></li>
            <li><img src="../../../assets/img/hz3.png" alt="至刚网"></li>
            <li><img src="../../../assets/img/hz4.png" alt="至刚网"></li>
            <li><img src="../../../assets/img/hz5.png" alt="至刚网"></li>
					</ul>
					<ul>
            <li><img src="../../../assets/img/hz6.png" alt="至刚网"></li>
            <li><img src="../../../assets/img/hz7.png" alt="至刚网"></li>
            <li><img src="../../../assets/img/hz8.png" alt="至刚网"></li>
            <li><img src="../../../assets/img/hz9.png" alt="至刚网"></li>
            <li><img src="../../../assets/img/hz0.png" alt="至刚网"></li>
					</ul>
				</div>
				<div></div>
			</div>
		</div>
	</div>
</template>

<script>
	import 'swiper/dist/css/swiper.css'

	export default {
		name: "platformAndCooperation",
		components: {


		},
		data() {
			return {
				platformList: [{
						gotoHref: "supplyChain",
						platformListItem: 0
					},
					{
						gotoHref: false,
						platformListItem: 0
					},
					{
						gotoHref: "https://rzn.ddmg.com/",
						platformListItem: 0
					},
					// {
					// //  gotoHref: "supplyChain",
					//   wxImg: "Industrychain_9",
					//   platformListItem: 0,needBG:true
					// },
				],
				swiperOption: {
					pagination: ".swiper-pagination1",
					slidesPerView: 6,
					slidesPerColumn: 1,
					slidesPerColumnFill: "row",
					centeredSlides: false,
					onSlideChangeEnd: (swiper) => {
						//放swiper的回调方法
						this.page = swiper.realIndex + 1;
						this.index = swiper.realIndex;
					},
					//左右导航栏
					navigation: {
						nextEl: ".swiper-button-next",
						prevEl: ".swiper-button-prev",
					},

					//循环
					loop: true,
				},
			};
		},
		methods: {
      handleSelect(item) {
        this.activeIndex = item;
        let routeData = this.$router.resolve({
          path: '/supply/index',
          query: {
            cur: item
          }
        });
        window.open(routeData.href, '_blank');
      },
		},
		computed: {},
		mounted() {

		},
	};
</script>

<style lang="scss">
	.platform {
		display: flex;
    background: #F9F9F9;
	  width: 100%;
    height: 334px;
		flex-direction: column;
	}

	.platform-header {
		display: flex;
		justify-content: space-between;
		 width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
	}

	.platform-title {
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 600;
    font-size: 18px;
    color: #000102;
    line-height: 26px;
    text-align: left;
    font-style: normal;
	}


	.platform-desc {
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 400;
    font-size: 12px;
    color: #999999;
    line-height: 17px;
    text-align: left;
    font-style: normal;
	}

	.platform-body {
		display: flex;
		justify-content: space-between;
    width: 1200px;
    margin: 12px auto;
	}

  .platform-body div {
     width:285px;
     height:204px;
  }
  .platform-body  .platformImg{
    cursor:pointer;
  }
  .platform-body div img {
    width:100%;
    height:100%;
  }
	.cooperation {
		display: flex;
		margin: 0px auto;
		background-color: #ffffff;
		max-width: 1200px;
		flex-direction: column;
    margin-bottom: 30px;
	}
  .cooperation-body  {
    margin: 10px 0;
  }
	.cooperation-body ul {
		display: flex;
		justify-content: space-between;
	}

	.cooperation-body ul li {
    width: 226px;
    height: 73px;
		margin: 10px 0;
	}
  .cooperation-body ul li img{
    width: 226px;
    height: 73px;
  }
</style>